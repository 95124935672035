import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@core/services/Auth0/auth.service';
import { OrderService } from '@core/services/order/order.service';
import { PersonSortService } from '@core/services/sorters/person/person-sort.service';
import { OrderSubscriptionService } from '@core/services/subscriptions/order-subscription.service';
import { OrderStatus } from '@enums/order-status.enum';
import { SubscriptionStatusIcons } from '@enums/subscription-status-icons-enum';
import { SubscriptionStatus } from '@enums/subscription-status.enum';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { Order } from '@models/order';
import { OrderSubscription } from '@models/subscription';
import { UserProfile } from '@models/user-profile';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html'
})
export class SubscribeComponent extends OnDestroySubscriptionResolver implements OnInit, OnDestroy {
  @Input() orderId: number = 0;
  @Input() order: Order = null;
  @Input() nullOrder: Order = null;
  @Input() userProfile: UserProfile = { associatedAgencies: [], agentId: 0 };
  @Input() subscriptions: Array<OrderSubscription> = null;
  @Input() reload$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  @Input() showCount: boolean = false;
  private _subscriptionStatus: SubscriptionStatus = SubscriptionStatus.unsubscribed;
  private _subscribableStatusIds: number[] = [OrderStatus.Open, OrderStatus.Closing];
  private _successSubscribeMessage: string = 'You have successfully subscribed to this order.';
  private _successUnsubscribeMessage: string = 'You have successfully unsubscribed from this order.';
  private _statusMessageTitle: string = 'Subscription Notification';
  public isSsoUser: boolean = false;

  public loaded: boolean = false;
  public showPopOver: boolean = false;
  public subscriptionStatusIcon: SubscriptionStatusIcons = SubscriptionStatusIcons.unsubscribed;

  public get order$(): Observable<Order> {
    if (this.order)
      return of(this.order);

    return this._orderService.getOrder$(this.orderId);
  }

  public get isSubscribedToOrder(): boolean {
    return this._subscriptionStatus === SubscriptionStatus.subscribed;
  }

  public get isPrimaryAgentOnOrder(): boolean {
    return this._subscriptionStatus === SubscriptionStatus.primaryAgent;
  }

  constructor(private readonly _subscriptionService: OrderSubscriptionService,
    private readonly _orderService: OrderService,
    private readonly _personSorter: PersonSortService,
    private readonly _authService: AuthService,
    private readonly _toastr: ToastrService) { super(); }

  ngOnInit(): void {
    this.order$
      .pipe(
        takeUntil(this._unsubscribe$$),
        map(order => {
          this.order = order;
          this.orderId = order?.orderId;
          if (this.order && this.orderId > 0 && this.userProfile?.agentId > 0)
            this.initialize();
        }))
      .subscribe();

    this.reload$
      .pipe(
        takeUntil(this._unsubscribe$$),
        filter(f => f === true),
        map(_ => {
          this.initialize();
        }))
      .subscribe();

    this._authService.isSSOUser$.pipe(
      takeUntil(this._unsubscribe$$),
      map(isSso => this.isSsoUser = isSso))
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public togglePopup(event: Event): void {
    event.stopPropagation();
    if (this._subscribableStatusIds.includes(this.order.orderStatusId)) {
      this.showPopOver = !this.showPopOver;
      this._personSorter.sortAscending(this.subscriptions);
    }
  }

  public changeSubscription(event: Event): void {
    event.stopPropagation();
    if(!this.isSsoUser) {
      if (this._subscriptionStatus === SubscriptionStatus.unsubscribed)
        this.subscribe();
      else
        this.unsubscribe();
    }
    else {
      this._toastr.clear();
      this._toastr.error('Only Agents can subscribe to orders');
    }
  }

  private initialize(): void {
    combineLatest([this.initializeSubscriptions(), this.initializeStatus(this.orderId, this.userProfile)])
      .pipe(
        takeUntil(this._unsubscribe$$),
        catchError(err => {
          this.loaded = true;
          this.showError(err);
          return of();
        }))
      .subscribe(_ => {
        this.loaded = true;
      });
  }

  private initializeSubscriptions(): Observable<void> {
    if (this.subscriptions) {
      this._subscriptionService.setOrderSubscriptions(this.orderId, this.subscriptions);
      return of(null);
    }

    return this._subscriptionService.getOrderSubscriptions(this.orderId)
      .pipe(
        takeUntil(this._unsubscribe$$),
        map(subscriptions => {
          this.subscriptions = subscriptions;
        }));
  }

  private initializeStatus(orderId: number, profile: UserProfile): Observable<void> {
    const isPrimary = this._subscriptionService.isPrimaryAgentOnOrder(this.order, profile);
    return this._subscriptionService.isSubscribedToOrder(orderId, profile)
      .pipe(
        map(isSubscribed => {
          this._subscriptionStatus = isPrimary ? SubscriptionStatus.primaryAgent : isSubscribed ? SubscriptionStatus.subscribed : SubscriptionStatus.unsubscribed;
          this.setBookmarkStyle();
        }));
  }

  private subscribe(): void {
    this._subscriptionService.addSubscriptionToOrder(this.orderId, this.userProfile, this.isSsoUser)
      .pipe(
        takeUntil(this._unsubscribe$$),
        map(response => {
          if (response.error) {
            this.showError(response.error);
            return;
          }
          this.subscribed(response.value);
        })
      )
      .subscribe();
  }

  private subscribed(subscription: OrderSubscription): void {
    this._subscriptionStatus = SubscriptionStatus.subscribed;
    this.showSuccess(this._successSubscribeMessage, this._statusMessageTitle);
    this.setBookmarkStyle();
  }

  private unsubscribe(): void {
    this._subscriptionService.removeSubscriptionFromOrder(this.orderId, this.userProfile, this.isSsoUser)
      .pipe(
        takeUntil(this._unsubscribe$$),
        map(response => {
          if (response.error) {
            this.showError(response.error);
            return;
          }
          this.unSubscribed(response.value);
        })
      )
      .subscribe();
  }

  private unSubscribed(subscription: OrderSubscription): void {
    this._subscriptionStatus = SubscriptionStatus.unsubscribed;
    this.showSuccess(this._successUnsubscribeMessage, this._statusMessageTitle);
    this.setBookmarkStyle();
  }

  private setBookmarkStyle(): void {
    switch (this._subscriptionStatus) {
      case SubscriptionStatus.primaryAgent:
        this.subscriptionStatusIcon = SubscriptionStatusIcons.primaryAgent;
        break;
      case SubscriptionStatus.subscribed:
        this.subscriptionStatusIcon = SubscriptionStatusIcons.subscribed;
        break;
      default:
        this.subscriptionStatusIcon = SubscriptionStatusIcons.unsubscribed;
    }
  }

  private showError(err: any): void {
    this._toastr.clear();
    this._toastr.error(err.message, err.title ?? 'Unknown Error', { timeOut: 10000, closeButton: false });
  }

  private showSuccess(message: string, title: string): void {
    this._toastr.clear();
    this._toastr.success(message, title, { disableTimeOut: false });
  }
}
