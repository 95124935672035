import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IBadgeService } from '@core/services/badges/IBadge-service';
import { Badge } from '@models/badge';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { Subscription } from 'rxjs';
import { delay, map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html'
})
export class BadgeComponent implements OnDestroy, AfterViewInit {

  @Input('data-service')
  service: IBadgeService<any>;

  @Input('css-class')
  public badgeClass: string[] = ['badging__counter', 'badging__counter--blue'];

  public badge: Badge = new Badge();
  private _subscriptions: Subscription;

  ngAfterViewInit(): void {
    this._subscriptions = new Subscription();
    this.addSubscription();
  }

  private addSubscription(): void {
    this._subscriptions.add(this.service.badge$$
      .pipe(
        startWith(this.badge),
        delay(0),
        map(badge => {
          this.badge = badge;
        })
      ).subscribe());
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
