import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateNoticeRulesService } from '@core/services/rules/notices/state-notice-rules.service';
import { NullablePersonSortService } from '@core/services/sorters/nullable-person/nullable-person-sort.service';
import { OrderSubscriptionService } from '@core/services/subscriptions/order-subscription.service';
import { UserService } from '@core/services/user/user.service';
import { AgentStatus } from '@enums/agent-status.enum';
import { Agency } from '@models/agency';
import { Agent } from '@models/agent';
import { OrderDetail } from '@models/order-detail';
import { OrderSubscription } from '@models/subscription';
import { FollowEventLocation } from '@shared/modules/adobe-analytics/models/custom-events-metadata';
import { CustomEventsService } from '@shared/modules/adobe-analytics/services/custom-events.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { OrderViewService } from 'src/app/modules/order-view/services/order-view.service';


@Component({
  selector: 'app-agents-popup',
  templateUrl: './agents-popup.component.html',
  providers: [OrderViewService]
})
export class AgentsPopupComponent implements OnInit, OnDestroy {
  public agencyId: number;
  public agency: Agency;
  public orderId: number;
  public agents: Array<Agent> = [];
  public showDropdown: boolean;
  public selectedAgents: Array<Agent> = [];
  public orderSubscriptions: Array<OrderSubscription> = [];
  public currentSubscriptions: Array<OrderSubscription>;
  public subscriptions: Subscription = new Subscription();
  public orderDetail: OrderDetail;

  public selectAll($event: Array<Agent>): void {
    this.selectedAgents = $event;
  }

  public deselectAll(): void {
    this.selectedAgents = [];
  }

  constructor(
    private _userService: UserService,
    private _modalService: BsModalService,
    private _subscriptionService: OrderSubscriptionService,
    private readonly _orderViewService: OrderViewService,
    private readonly _personSort: NullablePersonSortService,
    private readonly _customEventSvc: CustomEventsService,
    private readonly _stateNoticesService: StateNoticeRulesService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this._userService.getFilteredAgents$(this.agencyId, (agent => agent.statusCode !== AgentStatus.Deleted)).pipe(map(agent => { agent.forEach(i => i.agentName = i.firstName + ' ' + i.lastName); return agent; }))
        .subscribe(agents => {
          this._personSort.sortAscending(agents);
          this.agents = agents;
          this.subscriptions.add(
            this._userService.userProfile$.subscribe(user => {
              let agentIndx = this.agents.findIndex(a => a.agentId === user?.agentId);
              if (agentIndx > -1) {
                this.selectedAgents = [this.agents[agentIndx]];
              }
              if (this.currentSubscriptions[0]?.agentId !== user?.agentId) {
                let subIndx = this.agents.findIndex(x => x.agentId === this.currentSubscriptions[0]?.agentId);
                if (subIndx > -1) {
                  this.selectedAgents.push(this.agents[subIndx]);
                }
              }
              this.showDropdown = true;
            }));
        }));
        
        this._userService.getAgency$(this.agencyId).pipe(take(1)).subscribe(agency => this.agency = agency);
  }

  private hideModel(dismissalReason: string): void {
    this._modalService.setDismissReason(dismissalReason);
    this._modalService._hideModal(1);
  }

  public get dropdownSettings(): object {
    return {
      noneSelectedBtnText: 'Add Follower(s) to the Order',
      maxHeight: this.agents.length > 4 ? '150' : '300',
      text: "Add Follower(s) to the Order",
      searchBy: ["agentName"],
      badgeShowLimit: 1,
      primaryKey: "agentId",
      labelKey: "agentName",
      enableSearchFilter: true,
      searchAutofocus: true,
      classes: "dropdown"
    };
  }

  public submit(): void {
    this.orderSubscriptions = [];
    this.selectedAgents.map((agent) => {
      this.orderSubscriptions.push({
        agentId: agent.agentId,
        firstName: agent.firstName,
        lastName: agent.lastName,
        isActive: true
      });
    });

    this.subscriptions.add(
      this._orderViewService.updatePrimaryAgent(this.orderDetail?.orderId, this.orderSubscriptions[0]?.agentId, this.orderDetail?.loanNumber).subscribe()
    );

    this.subscriptions.add(
      this._subscriptionService.subscribeToOrder(this.orderId, this.orderSubscriptions).subscribe(() => this.hideModel('true'))
    );

    this._customEventSvc.pushSubscriberEvent(FollowEventLocation.Acceptance, true, this.orderSubscriptions.length);
  }

  public cancel(): void {
    this.hideModel('false');
  }

  public getNotice(stateCode: string | null): string {
    if (!stateCode) return '';

    const ruleSet = this._stateNoticesService.getRules(stateCode, this.orderDetail.orderStatusId);
    if (!ruleSet.hasRules)
      return '';

    return ruleSet.rules.map(r => r.notice).join(' ');
  }
}
