import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  FEATURE_FLAG_CONFIG,
  FeatureFlagModule, FeatureFlagProvider,
  SplitIoService
} from '@closing-portal/feature-flags';
import { SharedModule } from '@shared/shared.module';
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FooterComponent } from './components/footer/footer.component';
import { PdfViewerComponent } from './components/footer/pdf-viewer/pdf-viewer.component';
import { HeaderComponent } from './components/header/header.component';
import { HelpPageComponent } from './components/help-page/help-page.component';
import { OrderSearchInputComponent } from './components/order-search-input/order-search-input.component';
import { AddressHighlightComponent } from './components/order-search-list-item/address-highlight/address-highlight.component';
import { ContactHighlightComponent } from './components/order-search-list-item/contact-highlight/contact-highlight.component';
import { LoanNumberHighlightComponent } from './components/order-search-list-item/loan-number-highlight/loan-number-highlight.component';
import { OrderSearchListItemComponent } from './components/order-search-list-item/order-search-list-item.component';
import { OrderSearchListComponent } from './components/order-search-list/order-search-list.component';
import { UsageAgreementComponent } from './components/usage-agreement/usage-agreement.component';
import { UserSurveyComponent } from './components/user-survey/user-survey.component';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { FeatureFlagConfigBuilderService } from './services/split/feature-flag-config-builder.service';

@NgModule({
  declarations: [
    HeaderComponent,
    OrderSearchInputComponent,
    OrderSearchListItemComponent,
    UsageAgreementComponent,
    UserSurveyComponent,
    FooterComponent,
    PdfViewerComponent,
    HelpPageComponent,
    ContactUsComponent,
    AddressHighlightComponent,
    ContactHighlightComponent,
    LoanNumberHighlightComponent,
    OrderSearchListComponent
  ],
  imports: [
    SparkAngularModule,
    PdfViewerModule,
    CommonModule,
    SharedModule,
    FeatureFlagModule,
    RouterModule,
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: FEATURE_FLAG_CONFIG,
      useClass: FeatureFlagConfigBuilderService
    },
    { provide: FeatureFlagProvider, useClass: SplitIoService }
  ],
  exports: [
    HeaderComponent,
    OrderSearchInputComponent,
    UsageAgreementComponent,
    UserSurveyComponent,
    FooterComponent,
    HelpPageComponent,
    AddressHighlightComponent,
    ContactHighlightComponent,
    LoanNumberHighlightComponent
  ],
  bootstrap: []
})
export class CoreModule { }
