import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@enums/order-status.enum';
import { PartnershipType } from '@enums/partnership-enum';
import { TransactionType } from '@enums/transaction-type.enum';
import { Contact } from '@models/contact';
import { LoanData } from '@models/loan-data';
import { ContactType } from '@shared/enums/contact-type-converter.enum';

@Pipe({
  name: 'qlContact'
})
export class QlContactPipe implements PipeTransform {

  transform(contacts: Array<Contact>, orderStatus: number, loan: LoanData): Array<Contact> {
    let returnData: Array<Contact> = [];
    if (contacts) {
      returnData = contacts.filter(x => this.filterContacts(x, orderStatus));
    }

    if (returnData.length === 0) {
      switch (loan?.partnership?.toUpperCase()) {
        case (PartnershipType.Title):
          returnData.push({
            orderId: null,
            firstName: '',
            lastName: '',
            middleName: '',
            contactType: this.getContactType(orderStatus),
            contactTypeId: null,
            clientId: null,
            emailAddress: null,
            phoneNumber: 8334951251,
            phoneNumberExt: null,
            companyName: null,
            maritalStatus: null
          } as Contact);
          break;

        case (PartnershipType.Closing):
          returnData.push({
            orderId: null,
            firstName: '',
            lastName: '',
            middleName: '',
            contactType: this.getContactType(orderStatus),
            contactTypeId: null,
            clientId: null,
            emailAddress: null,
            phoneNumber: 8889802789,
            phoneNumberExt: null,
            companyName: null,
            maritalStatus: null
          } as Contact);
          break;

        default:
          returnData.push({
            orderId: null,
            firstName: '',
            lastName: '',
            middleName: '',
            contactType: this.getContactType(orderStatus),
            contactTypeId: null,
            clientId: null,
            emailAddress: null,
            phoneNumber: this.getPhoneNumber(orderStatus, loan?.isTPO),
            phoneNumberExt: null,
            companyName: null,
            maritalStatus: null
          } as Contact);
          break;
      }
    }

    return returnData;
  }

  private filterContacts(contact: Contact, orderStatus: OrderStatus): boolean {
    if (orderStatus === OrderStatus.Closing)
      return contact.contactType === ContactType.Closer;
    else if (orderStatus === OrderStatus.Open)
      return contact.contactType === ContactType.LoanAnalyst;

    return false;
  }

  private getContactType(orderStatus: OrderStatus): string {
    return orderStatus == OrderStatus.Closing ? 'Closing Hotline' : 'Loan Analyst Hotline';
  }

  private getPhoneNumber(orderStatus: OrderStatus, isTPO: boolean | undefined): number {
    if(orderStatus === OrderStatus.Closing) {
      return isTPO ? 8885894055 : 8888485309;
    }
    else
      return isTPO ? 8663126192 : 8889122272;
  }

}
