import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { OrderService } from '@core/services/order/order.service';
import { OrderStatus } from '@enums/order-status.enum';
import { OrderDetail } from '@models/order-detail';
import { SearchResults } from '@models/search-results';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AgentsPopupComponent } from '../agents-popup/agents-popup.component';

@Component({
  template: ''
})
export class NewOrderListComponent implements OnInit, OnDestroy {
  public searchResults: SearchResults;
  protected updateOrder$: BehaviorSubject<OrderDetail> = new BehaviorSubject<OrderDetail>(null);
  protected newOrderBadgeCount: number = 0;
  protected popUpModal: TemplateRef<any>;
  protected acceptModalLevel: number;
  protected _subscriptions: Subscription;
  private _bsModalRef: BsModalRef;
  private _popUpResult: boolean;

  constructor(
    private readonly _modalSvc: BsModalService,
    private readonly _orderSvc: OrderService) { }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();
  }

  public openModal(orderDetail: OrderDetail): void {
    this._modalSvc.onHide.pipe(take(1)).subscribe(() => {
      if (this._popUpResult) {
        this.updateOrderStatus(orderDetail, OrderStatus.Denied);
      }
    });

    this._bsModalRef = this._modalSvc.show(this.popUpModal, {
      class: 'sprk-c-Modal upload-modal',
      ignoreBackdropClick: true
    });
  }

  /** Used to accept orders */
  public showSubscriptions(orderDetail: OrderDetail): void {
    this._modalSvc.onHide.pipe(take(1)).subscribe(reason => {
      if (reason === 'true')
        this.updateOrderStatus(orderDetail, OrderStatus.Open);
      else
        this._bsModalRef.hide();
    });

    this._bsModalRef = this._modalSvc.show(AgentsPopupComponent, {
      ignoreBackdropClick: true,
      class: 'sprk-c-Modal accept-modal',
      initialState: {
        agencyId: orderDetail.agencyId,
        orderId: orderDetail.orderId,
        orderStatusId: orderDetail.orderStatusId,
        loanNumber: orderDetail.loanNumber,
        currentSubscriptions: orderDetail.subscriptions,
        orderDetail: orderDetail,
        level: this.acceptModalLevel
      }
    });
  }

  /** Called from modal */
  public declineOrder(event: boolean): void {
    this._popUpResult = event;
    this._bsModalRef.hide();
  }

  /** Only happens when accepting or declining order */
  protected updateOrderStatus(orderDetail: OrderDetail, orderStatusId: OrderStatus): void {
    orderDetail.orderStatusId = orderStatusId;
    this._subscriptions.add(
      this._orderSvc.updateOrderStatus$(orderDetail.orderId, orderDetail.orderStatusId, orderDetail.loanNumber)
        .subscribe(_ => {
          this.updateOrder$.next(orderDetail);
        })
    );
  }
}
