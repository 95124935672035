import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { FeatureFlagProvider } from '@closing-portal/feature-flags';
import { SessionCamService } from '@closing-portal/session-cam';
import { UserSurveyComponent } from '@core/components/user-survey/user-survey.component';
import { AuthService } from '@core/services/Auth0/auth.service';
import { FeatureService } from '@core/services/feature/feature.service';
import { NavigationHistoryService } from '@core/services/navigation/navigation-history.service';
import { UserService } from '@core/services/user/user.service';
import { FeatureFlags } from '@enums/feature-flags.enum';
import { PreferenceCodeEnum } from '@enums/preference-code.enum';
import { SessionStorageKey } from '@enums/session-storage-key.enum';
import { OnDestroySubscriptionResolver } from '@models/ng-destroy-subscription-resolver';
import { UserProfile } from '@models/user-profile';
import { LivePersonChatService } from '@shared/modules/chat-factory/liveperson-chat.service';
import { SalesForceChatService } from '@shared/modules/chat-factory/salesforce-chat.service';
import { IAccount, IVisitor } from '@shared/modules/pendo/models/pendo-meta-data';
import { PendoService } from '@shared/modules/pendo/services/pendo.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { first, map, take, takeUntil } from 'rxjs/operators';
import { SignalRService } from './modules/order-view/services/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends OnDestroySubscriptionResolver implements OnInit, AfterViewInit {
  public isLoggedIn = false;
  constructor(
    private readonly _authService: AuthService,
    private readonly _featureFlagProvider: FeatureFlagProvider,
    private readonly _featureService: FeatureService,
    private readonly _historyService: NavigationHistoryService,
    private readonly _userService: UserService,
    private readonly _pendoService: PendoService,
    private readonly _sessionCamService: SessionCamService,
    private readonly _livePersonService: LivePersonChatService,
    private readonly _salesforceChatService: SalesForceChatService,
    private readonly _modalService: BsModalService,
    private readonly _signalRService: SignalRService) { 
      super();
      window.addEventListener('beforeunload', function (e) {
        _signalRService.closeConnection();
      });
    }

  public ngOnInit(): void {
    const initializeSessionCam = (isEnabled: boolean) => this._sessionCamService.initialize$(of(isEnabled), true).subscribe();
    this._featureFlagProvider.getFeatureFlagWhenReady$(FeatureFlags.SessionCam, initializeSessionCam);
    this._featureService.configureFeatureVersions();
    this._historyService.init();
    this._authService.isLoggedIn$
      .pipe(
        takeUntil(this._unsubscribe$$),
        map((isLoggedIn => {
          this.isLoggedIn = isLoggedIn;
          this.tryStoreAdminAgency(isLoggedIn);
        }))
      )
      .subscribe();

    this._livePersonService.instantiate();
    this._salesforceChatService.instantiate();
    this.openUserSurvey();
  }

  public ngAfterViewInit(): void {
    this._userService.userProfile$
      .pipe(
        take(1),
        map(profile => {
          this.initializePendo(profile);
        })
      ).subscribe();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._pendoService.clearSession();
    this._sessionCamService.destroy();
    this._signalRService.closeConnection();
  }

  public openUserSurvey(): void {
    this._userService.agentPreferences$$.pipe(map(agentPrefs => {
      if((agentPrefs == null || agentPrefs.length === 0) || (agentPrefs.findIndex(ap => ap.preferenceCode == PreferenceCodeEnum.UserSurvey) == -1)) {
        this._modalService.show(UserSurveyComponent, {
          class: 'sprk-c-Modal sprk-c-Modal--hidden-scroll',
          ignoreBackdropClick: true,
          initialState: {
            agentPrefs: agentPrefs
          }
        });
      }
    }),first()).subscribe();
  }

  private tryStoreAdminAgency(isLoggedIn: boolean): void {
    const agencyIdParam = 'agencyId';
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams.has(agencyIdParam) && !isLoggedIn) {
      sessionStorage.setItem(SessionStorageKey.AdminAgency, urlSearchParams.get(agencyIdParam));
    }
  }

  private initializePendo(profile: UserProfile): void {
    let visitor: IVisitor = null;
    let account: IAccount = null;
    const agency = profile.associatedAgencies.find(a => a.agencyId === this._userService.targetAgencyId);

    visitor = {
      id: profile.agentId.toString(),
      email: profile.emailAddress,
      full_name: `${profile.firstName} ${profile.lastName}`
    };

    account = {
      id: agency.agencyId.toString(),
      name: agency.name,
      location: `${agency.city}, ${agency.stateCode}`
    };

    this._pendoService.initialize(visitor, account, this._featureFlagProvider.canShowFeature$(FeatureFlags.Pendo));
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    if (+sessionStorage.getItem(SessionStorageKey.AdminAgency)) {
      this._authService.logout();
    }

    this._featureFlagProvider.dispose();
  }
}
