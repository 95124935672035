export enum FeatureFlags {
  Pendo = 'Pendo',
  DocumentUploadV2 = 'Document_Upload_v2',
  DocumentUploadVersion = 'Document_Upload_Version',
  SalesForceLiveChat = 'SalesForce_LiveChat',
  SessionCam = 'SessionCam',
  MilestoneApiVersion = 'Milestone_API_Version',
  MessageApiVersion = 'Message_API_Version',
  HeaderVersion = 'Header_Version',
  PreferredEmail = 'PreferredEmail',
  LoanDataApiVersion = 'UI_Loan_Service_Version',
  ClosingType = 'ClosingType'
}
